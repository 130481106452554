import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/yves-hoppe.jpg'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <a href="https://yveshoppe.de" className="image avatar"><img src={avatar} alt="Yves Hoppe"/></a>
          <h1><strong>Yves Hoppe</strong><br/>
            Open Source Evangelist<br/>
            Software Architect, DevOps<br/>
            Lecturer and Speaker<br/>
            Creator of <a href="https://empoche.com" title="Next gen Time Tracking and Task Management" target="_blank" rel="noopener noreferrer">Empoche</a> and <a
              href="https://mame.app" title="Ultra fast website builder" target="_blank" rel="noopener noreferrer">Mame</a>.<br/>
          </h1>
          <h5>Obsessed with <a href="https://gentoo.org" target="_blank" rel="noopener noreferrer">#Gentoo</a> and <a
            href="https://archlinux.org" target="_blank" rel="noopener noreferrer">#ArchLinux</a>,<br/> software quality, security, tech,
            web and space.</h5>
        </div>
        <Footer/>
      </header>
    )
  }
}

export default Header
