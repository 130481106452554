import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

import emailjs from 'emailjs-com';

class HomeIndex extends React.Component {

  constructor() {
    super();

    this.inputFromName = React.createRef();
    this.inputReplyTo = React.createRef();
    this.inputMessage = React.createRef();

    this.state = {
      successMessage: '',
      errorMessage: '',
    }
  }

  handleFormSubmit() {
    const serviceId = 'default_service';
    const templateId = 'template_OhXwLH3T';
    const userId = 'user_5Mt2hWnZeWtqvo0QPSJj3';

    const data = {
      from_name: this.inputFromName.current.value,
      reply_to: this.inputReplyTo.current.value,
      message: this.inputMessage.current.value,
    };

    if (!data.from_name || !data.reply_to || !this.inputMessage) {
      this.setState({
        successMessage: '',
        errorMessage: 'Please fill out all required fields.',
      });

      return;
    }

    emailjs.send(serviceId, templateId, data, userId);

    this.setState({
      successMessage: 'Message sent.',
      errorMessage: '',
    });
  }

  render() {
    const siteTitle = "Yves Hoppe";
    const siteDescription = "Yves Hoppe | Software Architect | Lecturer | Linux / Unix";

    return (
      <Layout>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription}/>
          <link rel="canonical" href="https://yveshoppe.de"/>
        </Helmet>

        <div id="main">

          <section id="one">
            <header className="major">
              <h2><i className="icon fa-quote-left"></i> If you can dream it, you can do it.
                <br/>
                <small>Walt Disney</small>
              </h2>
            </header>
            <p>Working on various high profile business and government clients, implementing and teaching various
              Software Architecture, Security, DevOps and Linux / Unix topics. Long term Open Source contributor and
              user.</p>

            <p>I prefer presenting ideas over presenting myself. But it seems that no freelancer
              or trainer exists without an "About" page. So rather than listing all things I've achieved and failed at,
              I am just going to share my profile and spare you the marketing speech,
              everything else can or can not be found on <a
                href='https://www.google.com/search?q="yves+hoppe"' target="_blank" rel="noopener noreferrer">Google</a>.
            </p>
            <ul className="actions">
              <li><a href="#trainer-profile" className="button">Learn More</a></li>
              <li><a href="#contact" className="button">Contact</a></li>
            </ul>
          </section>

          <section id="two">
            <h2><a name="trainer-profile">Trainer Profile</a></h2>
            <p>More than 2000 training and consulting days since 2010 for countless companies in Germany and Europe.
              Speaker on international and national conferences and congresses.</p>

            <h4>Linux / Unix</h4>
            <ul>
              <li>General Linux / Unix</li>
              <li>Security</li>
              <li>Shell Scripting</li>
              <li>Webserver (Apache / Nginx)</li>
              <li>DNS / DHCP Server</li>
              <li>LDAP &amp; Samba</li>
              <li>LXC / Docker</li>
              <li>Kubernetes</li>
              <li>DevOps</li>
              <li>Ansible</li>
              <li>Monitoring</li>
              <li>..</li>
            </ul>

            <h4>Languages</h4>
            <ul>
              <li>JavaScript</li>
              <li>Python</li>
              <li>Java / Java EE</li>
              <li>PHP</li>
              <li>Bash / Shell</li>
              <li>..</li>
            </ul>

            <h4>Frameworks</h4>
            <ul>
              <li>Node.js</li>
              <li>Express / Sails.js / Loopback</li>
              <li>GraphQL</li>
              <li>Vue.js</li>
              <li>React</li>
              <li>Electron</li>
              <li>Gatsby / Next.js</li>
              <li>Pandas / Numpy</li>
              <li>Tensorflow</li>
              <li>Spring Framework</li>
              <li>Spring Boot</li>
              <li>Laravel</li>
              <li>..</li>
            </ul>

            <h4>Databases</h4>
            <ul>
              <li>MongoDB</li>
              <li>Firebase</li>
              <li>PostgreSQL</li>
              <li>MySQL / Mariadb</li>
              <li>Oracle</li>
              <li>..</li>
            </ul>

            <h4>Certification Training</h4>
            <ul>
              <li>Linux Essentials</li>
              <li>LPIC-1 (Linux Administrator)</li>
              <li>LPIC-2 (Linux Engineer)</li>
              <li>Red Hat Certifications (RHCE, RHCSA..)</li>
              <li>..</li>
            </ul>
          </section>

          <section id="three">
            <h2><a name="contact">Get In Touch</a></h2>
            <p>
              I am available to lecture and speak on a range of topics anywhere in the world.
              Or <strong>if</strong> you have an <strong>interesting</strong> and revolutionary project, you may be able
              to hire me as project manager,
              architect or DevOps specialist.
            </p>
            <div className="row">
              <div className="8u 12u$(small)">
                <form method="post" action="#">
                  <div className="row uniform 50%">
                    <div className="12u">
                      {this.state.successMessage}
                      {this.state.errorMessage}
                    </div>
                    <div className="6u 12u$(xsmall)">
                      <input aria-label="Your Name" type="text" id="name" placeholder="Name" ref={this.inputFromName}/>
                    </div>
                    <div className="6u 12u$(xsmall)">
                      <input aria-label="E-Mail" type="email" id="email" placeholder="Email" ref={this.inputReplyTo}/>
                    </div>
                    <div className="12u"><textarea aria-label="Message" name="message" id="message" placeholder="Message"
                                                   rows="4" ref={this.inputMessage}></textarea></div>
                  </div>
                </form>
                <ul className="actions">
                  <li><input type="submit" aria-label="Send Message" value="Send Message" onClick={() => this.handleFormSubmit()}/></li>
                </ul>
              </div>
              <div className="4u 12u$(small)">
                <ul className="labeled-icons">
                  <li>
                    <h3 className="icon fa-globe"><span className="label">Address</span></h3>
                    Anywhere in the world. Living in Munich, Germany
                  </li>
                  <li>
                    <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                    (+49) 0151 / 111 0 3000
                  </li>
                  <li>
                    <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                    <a href="mailto:contact@yves-hoppe.de">contact@yves-hoppe.de</a>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section id="four">
            <h2>Links</h2>
            <p>Links to my Profiles, Projects and Organizations.</p>
            <div className="row">
              <div className="4u 12u$(small)">
                <ul className="links">
                  <li><i className="icon fa-external-link"></i> <a href="https://bytee.net" rel="noopener noreferrer"
                                                                   title="Bytee - Linux and Unix Blog" target="_blank">My
                    Blog</a></li>
                  <li><i className="icon fa-external-link"></i> <a href="https://g5c.de" rel="noopener noreferrer"
                                                                   title="g5C Web Development" target="_blank">g5C</a>
                  </li>
                  <li><i className="icon fa-external-link"></i> <a href="https://empoche.com" rel="noopener noreferrer"
                                                                   title="Empoche - Tasks and TODOs for Linux, MacOS and Windows"
                                                                   target="_blank">Empoche App</a></li>
                  <li><i className="icon fa-external-link"></i> <a href="https://mame.app" rel="noopener noreferrer"
                                                                   title="Mame - Website Builder with focus on performance"
                                                                   target="_blank">Mame Builder</a></li>

                  <li><i className="icon fa-external-link"></i> <a href="https://nowpass.org" rel="noopener noreferrer"
                                                                   title="nowpass - Next generation password manager"
                                                                   target="_blank">nowpass</a></li>
                  <li><i className="icon fa-external-link"></i> <a href="https://cmt.de" rel="noopener noreferrer"
                                                                   title="Linux / Unix Seminars in Munich"
                                                                   target="_blank">cmt</a></li>
                </ul>
              </div>
              <div className="4u 12u$(small)">
                <ul className="links">
                  <li><i className="icon fa-external-link"></i> <a href="https://compojoom.com"
                                                                   rel="noopener noreferrer"
                                                                   title="Joomla Extensions and Development"
                                                                   target="_blank">Compojoom</a></li>
                  <li><i className="icon fa-external-link"></i> <a href="https://bytee.net/quiz"
                                                                   rel="noopener noreferrer"
                                                                   title="Linux Professional Institute Quiz / Practice Exams"
                                                                   target="_blank">LPIC Quiz</a></li>
                  <li><i className="icon fa-external-link"></i> <a
                    href="https://volunteers.joomla.org/joomlers/683-yves-hoppe" rel="noopener noreferrer"
                    title="My Joomla Volunteer Profile" target="_blank">Joomla Volunteer Profile</a>
                  </li>
                  <li><i className="icon fa-external-link"></i> <a href="https://community.vmug.com/yveshoppe" rel="noopener noreferrer"
                                                                   title="VMUG"
                                                                   target="_blank">VMUG</a></li>
                  <li><i className="icon fa-external-link"></i> <a href="https://www.youtube.com/channel/UCsX7jHlKSpb64dM-82zT5sQ" rel="noopener noreferrer"
                                                                   title="My YouTube channel"
                                                                   target="_blank">YouTube Channel</a></li>
                </ul>
              </div>
              <div className="4u 12u$(small)">
                <ul className="links">
                  <li><i className="icon fa-external-link"></i> <a href="https://github.com/yvesh"
                                                                   rel="noopener noreferrer"
                                                                   title="My GitHub Account" target="_blank">GitHub</a>
                  </li>
                  <li><i className="icon fa-external-link"></i> <a href="https://gist.github.com/yvesh"
                                                                   rel="noopener noreferrer"
                                                                   title="My Gists" target="_blank">Gists</a>
                  </li>
                  <li><i className="icon fa-external-link"></i> <a href="https://gitlab.com/yveshoppe"
                                                                   rel="noopener noreferrer"
                                                                   title="My GitLab Account" target="_blank">GitLab</a>
                  </li>
                  <li><i className="icon fa-external-link"></i> <a href="https://de.linkedin.com/in/yveshoppe"
                                                                   rel="noopener noreferrer"
                                                                   title="My LinkedIn Account"
                                                                   target="_blank">LinkedIn</a></li>
                  <li><i className="icon fa-external-link"></i> <a href="https://www.xing.com/profile/Yves_Hoppe/"
                                                                   rel="noopener noreferrer"
                                                                   title="My Xing account"
                                                                   target="_blank">Xing</a></li>
                </ul>
              </div>
            </div>
          </section>

        </div>

      </Layout>
    )
  }
}

export default HomeIndex;
